import { render, staticRenderFns } from "./BillingData.vue?vue&type=template&id=5d0b7482&scoped=true&"
import script from "./BillingData.vue?vue&type=script&lang=js&"
export * from "./BillingData.vue?vue&type=script&lang=js&"
import style0 from "./BillingData.vue?vue&type=style&index=0&id=5d0b7482&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d0b7482",
  null
  
)

export default component.exports