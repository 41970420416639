<template>
  <div :disabled="!isOnboarding">
    <v-row>
      <h2 class="pr-4 mb-2 d-inline">
        Angaben zum Rechnungsempfänger
      </h2>
    </v-row>
    <v-row class="d-flex justify-space-between">
      <v-col
        cols="12"
        md="6"
        class="pr-0 pa-0 pr-md-2"
      >
        <v-text-field
          ref="firstname"
          v-model.lazy="data.firstname"
          outlined
          label="Vorname"
          placeholder="Max"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="pa-0"
      >
        <v-text-field
          ref="lastname"
          v-model.lazy="data.lastname"
          outlined
          label="Nachname"
          placeholder="Makler"
        />
      </v-col>
    </v-row>
    <v-row class="d-flex justify-space-between">
      <v-col
        cols="12"
        md="6"
        class="pr-0 pa-0 pr-md-2"
      >
        <PhoneField
          v-model="data.phone"
          :disabled="!isOnboarding"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="pa-0"
      >
        <v-text-field
          ref="email"
          v-model.lazy="data.email"
          type="email"
          outlined
          label="E-Mail"
          placeholder="info@max-makler.de"
          :rules="[rules.email]"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-text-field
        ref="companyName"
        v-model.lazy="companyName"
        outlined
        :counter="companyName.length > 90"
        label="Firmenname (wenn abweichend)"
        :placeholder="companyName || 'Max Makler Immobilien GmbH'"
        :rules="[rules.length({max: 100})]"
      />
    </v-row>
    <v-row>
      <v-text-field
        v-if="showCompanyNameAddition"
        ref="companyNameAddition"
        v-model.lazy="data.companyNameAddition"
        outlined
        label="Firmenname Zusatz (bei über 100 Zeichen)"
        placeholder="Max Makler Immobilien GmbH"
        :rules="[rules.length({max: 50})]"
      />
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="3"
        class="pa-0 mr"
      >
        <v-text-field
          ref="vatId"
          v-model.lazy="data.vatId"
          outlined
          label="Umsatzsteuer ID"
          :placeholder="placeholder.vatId"
          :rules="[rules.vatId]"
          :disabled="data.vatIdRequested"
        />
      </v-col>
      <v-col
        cols="12"
        md="8"
        class="pl-6 mt-0 pa-0"
      >
        <v-checkbox
          ref="vatId"
          v-model.lazy="data.vatIdRequested"
          class="pr-4 mt-0"
          :disabled="!isOnboarding"
          outlined
          label="Ich habe noch keine Umsatzsteuer ID. Diese wurde bereits beantragt."
        />
      </v-col>
      <v-col
        v-if="data.vatIdRequested"
        cols="4"
        md="2"
        class="pa-0"
      >
        <v-menu
          v-model="vatIdRequestDateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="vatIdRequestDate"
              label="Beantragt am"
              outlined
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="vatIdRequestDate"
            :max="getEndDate"
            color="primary"
            :rules="data.vatIdRequested ? [rules.required] : []"
            @change="vatIdRequestDateMenu = false"
          />
        </v-menu>
      </v-col>
    </v-row>

    <v-row>
      <h2 class="pr-4 d-inline">
        Rechnungsanschrift
      </h2>
    </v-row>
    <v-row class="mt-1">
      <v-checkbox
        v-model="data.hasSeparateBillingAddress"
        :disabled="!isOnboarding"
        dense
      >
        <template
          v-slot:label
          class="font-weight-bold"
        >
          <span class="text-h6">
            Abweichend von Firmenanschrift
          </span>
        </template>
      </v-checkbox>
    </v-row>
    <v-row>
      <v-text-field
        ref="street"
        v-model.lazy="billingAddress.street"
        :disabled="!data.hasSeparateBillingAddress"
        outlined
        label="Straße"
        :placeholder="data.companyStreet || 'Musterstraße 3'"
        :rules="data.hasSeparateBillingAddress ? [rules.required] : []"
        @input="updateBillingAddress()"
      />
    </v-row>
    <v-row class="mb-6 d-flex justify-space-between">
      <v-col
        cols="12"
        md="4"
        class="pr-0 pa-0 pr-md-2"
      >
        <v-text-field
          ref="street"
          v-model.lazy="billingAddress.zip"
          :disabled="!data.hasSeparateBillingAddress"
          outlined
          type="number"
          label="PLZ"
          :placeholder="data.companyZip || '12345'"
          :rules="[
            rules.length({min: 4, max: 5}),
            rules.zip,
            ...(data.hasSeparateBillingAddress ? [rules.required] : [])
          ]"
          @input="updateBillingAddress()"
        />
      </v-col>
      <v-col
        cols="12"
        md="8"
        class="pa-0"
      >
        <v-text-field
          ref="city"
          v-model.lazy="billingAddress.city"
          :disabled="!data.hasSeparateBillingAddress"
          outlined
          label="Stadt"
          :placeholder="data.companyCity || 'Musterstadt'"
          :rules="data.hasSeparateBillingAddress ? [rules.required] : []"
          @input="updateBillingAddress()"
        />
      </v-col>
    </v-row>

    <v-row>
      <h2 class="pr-4 d-inline">
        Rechnungsstellung
      </h2>
    </v-row>
    <v-row>
      <v-radio-group
        v-model="data.paymentMethod"
        row
      >
        <v-radio
          v-for="{value, name: label} in paymentMethods"
          :key="value"
          :label="label"
          :value="value"
          disabled
        />
      </v-radio-group>
    </v-row>
    <v-row
      v-if="data.companyCountry !== 'ch' && data.paymentMethod === 'DIRECT_DEBIT'"
      class="d-flex justify-space-between"
    >
      <v-col
        cols="12"
        md="8"
        class="pr-0 pa-0 pr-md-2"
      >
        <v-text-field
          ref="iban"
          v-model.lazy="data.iban"
          outlined
          label="IBAN"
          :disabled="!isOnboarding"
          :placeholder="placeholder.iban"
          :rules="[rules.iban]"
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
        class="pa-0"
      >
        <v-text-field
          ref="bic"
          v-model.lazy="data.bic"
          outlined
          label="BIC"
          :disabled="!isOnboarding"
          placeholder="COLSDE33"
          :rules="[rules.bic]"
        />
      </v-col>
      <v-col
        cols="12"
        class="pa-0"
      >
        <h4 class="pb-4">
          Damit wir den Rechungsbetrag von Ihnen einziehen können,
          laden Sie bitte das ausgefüllte und unterschriebene SEPA-Lastschriftmandat hoch.
        </h4>
        <div class="pb-4">
          Sie können hier das
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/onboarding/BOTTIMMO-SEPA.pdf"
            class="font-weight-bold"
          >SEPA-Mandatsformular</a> herunterladen.
          <div class="justify-center py-4 d-flex align-center flex-column flex-md-row justify-md-start">
            <span class="mr-md-1">Bitte tragen Sie diese Mandatsreferenz ein: </span>
            <span class="font-weight-bold text-h6"> {{ sepaMandateReference }} </span>
            <div
              class="ml-2 pa-1 copy-btn"
              @click="copyMandateReference"
            >
              <v-icon alt="Mandatsreferenz in die Zwischenablage kopieren">
                mdi-content-copy
              </v-icon>
            </div>
          </div>
        </div>
        <v-row>
          <v-col
            cols="12"
            md="8"
          >
            <v-file-input
              v-model.lazy="data.sepaInput"
              outlined
              accept="image/jpeg,application/pdf"
              label="SEPA-Lastschriftmandat"
              :disabled="Boolean(data.onboardingSepa && data.onboardingSepa.url)"
              class="px-0 px-md-16"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-menu
              v-model="sepaMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="sepaValidAsOf"
                  label="SEPA unterschrieben am"
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="sepaValidAsOf"
                :max="getEndDate"
                color="primary"
                @change="sepaMenu = false"
              />
            </v-menu>
          </v-col>
        </v-row>
        <div
          v-if="data.onboardingSepa && data.onboardingSepa.url"
          class="truncate"
        >
          Ihr hochgeladenes SEPA-Lastschriftmandat:
          <a
            :href="data.onboardingSepa.url"
            target="_blank"
            rel="noopener noreferrer"
          >{{ data.onboardingSepa.name }}</a>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import IBAN from 'iban'
import { ISO_DATE } from '@/lib/date'
import { email, zip, empty, length, required } from '@/lib/validation'
import { PAYMENT_METHODS, PAYMENT_METHODS_CH } from './labels'
import bus, { eventNames } from '@/lib/eventBus'
import PhoneField from '@/components/PhoneField.vue'

const VAT_ID = /^(ATU[0-9]{8}|DE[0-9]{9})$|^((CHE)(-|\s)?|)\d{3}(\.|\s)?\d{3}(\.|\s)?\d{3}(\s)?(IVA|TVA|MWST|VAT)?$/

const placeholder = {
  DE: {
    iban: 'DE89 3704 0044 0532 0130 00',
    vatId: 'DE123456789'
  },
  AT: {
    iban: 'AT89 3704 0044 0532 0130 00',
    vatId: 'ATU12345678'
  },
  CH: {
    vatId: 'CHE123456789'
  }
}

export default {
  components: {
    PhoneField
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    isOnboarding: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      ISO_DATE: ISO_DATE,
      rules: {
        email,
        zip,
        length,
        required,
        vatId: (value) => empty(value) || VAT_ID.test(value) || 'Bitte gültige Ust-ID eintragen (DE/AT/CHE + 9 stellige ID)',
        bic: (value) => empty(value) || /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/.test(value) || 'Bitte eine valide BIC eintragen',
        iban: (value) => empty(value) || (IBAN.isValid(value)) || 'Bitte gültige IBAN eintragen'
      },
      sepaMenu: false,
      sepaValidAsOf: this.formatDate(this.data.sepaValidAsOf, ISO_DATE),
      vatIdRequestDateMenu: false,
      vatIdRequestDate: this.formatDate(this.data.vatIdRequestDate, ISO_DATE),
      companyName: this.data.companyName
    }
  },
  computed: {
    paymentMethods () {
      return this.data.companyCountry === 'ch' ? PAYMENT_METHODS_CH : PAYMENT_METHODS
    },
    placeholder () {
      return placeholder[this.data.companyCountry] || {}
    },
    sepaMandateReference () {
      return 'BOTTIMMO-' + this.data.customerNumber.padStart(6, '0')
    },
    showCompanyNameAddition () {
      return this.data.companyNameAddition || this.data.companyNameExceeded
    },
    getEndDate () {
      var endDate = new Date()
      return endDate.toISOString().slice(0, 10)
    },
    billingAddress () {
      const defaultBillingAddress = {
        city: this.data.city,
        street: this.data.street,
        zip: this.data.zip
      }
      const companyAddress = {
        city: this.data.companyCity,
        street: this.data.companyStreet,
        zip: this.data.companyZip
      }
      return this.data.hasSeparateBillingAddress ? defaultBillingAddress : companyAddress
    }
  },
  watch: {
    companyName (value) {
      if (value.length > 90) {
        this.data.companyNameExceeded = true
      }
    },
    sepaValidAsOf (value) {
      this.data.sepaValidAsOf = new Date(value)
    },
    vatIdRequestDate (value) {
      this.data.vatIdRequestDate = new Date(value)
    }
  },
  methods: {
    updateBillingAddress () {
      this.$emit('updateBillingAddress', this.billingAddress)
    },
    copyMandateReference () {
      navigator.clipboard.writeText(this.sepaMandateReference)
      bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: 'Mandatsreferenz kopiert' })
    }
  }
}
</script>
<style scoped>
.copy-btn{
 border-radius: 50px;
}
.copy-btn:hover{
 background-color: rgba(0,0,0,0.1);
 cursor: pointer;
}
</style>
